import { twMerge } from 'tailwind-merge';

import type { PropsWithChildren, ReactNode } from 'react';

export interface CardProps
  extends PropsWithChildren,
  React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  childrenClassName?: string;
  footerClassName?: string;
  imgAlt?: string;
  imgSrc?: string;
  imgClassName?: string;
  footer?: ReactNode;
  isPreview?: boolean;
}
export const cardBaseStyles =
  'grid auto-rows-min justify-between gap-5 sm:gap-x-6 p-4 border bg-white group-focus:border-green-900 group-focus:ring-green-900 group-focus:ring-1';

export const ProductCard = ({
  children,
  className,
  childrenClassName,
  footerClassName,
  imgAlt,
  imgSrc,
  imgClassName,
  footer,
  isPreview,
  ...props
}: CardProps) => {
  return (
    <div
      className={twMerge(cardBaseStyles, className)}
      data-testid="product-card"
      {...props}
    >
      <div
        data-testid="product-card-children"
        className={twMerge(
          'min-w-0 col-start-1 col-span-1 row-start-1 row-span-1 flex flex-col gap-2',
          childrenClassName
        )}
      >
        {children}
      </div>

      {footer && (
        <div
          data-testid="product-card-footer"
          className={twMerge(
            'col-start-1 col-span-2 row-start-2 row-span-1 min-w-0 ellipses',
            !isPreview && 'sm:col-span-1',
            footerClassName
          )}
        >
          {footer}
        </div>
      )}

      {imgSrc && (
        <div
          className={twMerge(
            'relative w-16 h-full max-h-16 min-h-12 col-start-2 col-span-1 row-start-1',
            !isPreview &&
            'sm:w-24 md:w-28 sm:max-h-24 md:max-h-28 sm:row-span-2'
          )}
        >
          <img
            className={twMerge(
              'absolute rounded-md w-full h-full object-cover',
              imgClassName
            )}
            alt={imgAlt ?? ''}
            src={imgSrc}
          />
        </div>
      )}
    </div>
  );
};
export default ProductCard;
