import { Link } from '@remix-run/react';
import { twMerge } from 'tailwind-merge';

import { Badge, getCardParentClasses } from '-/aslan';
import { useT } from '-/i18n';
import { camelize, cardImageUrl } from '-/utils';
import { ProductCard } from './ui/ProductCard';
import { H4, Paragraph } from './ui/Typography';

import type { TFunction } from '-/i18n';
import type { Block, Price } from '../models/block';

interface CalloutProps extends Block {
  to?: string;
  isPreview?: boolean;
  isExternal: boolean;
  className?: string;
}

const getBadgeLabel = (t: TFunction, isExternal: boolean, price?: Price) => {
  if (isExternal || !price) return t('callout.badge.open');
  if (price?.priceType)
    return t(`callout.badge.${camelize(price.priceType)}`, {
      amount: price.amount,
    });
  console.warn('Invalid usage of leap-pages:getBadgeLabel - undefined price');
  return t('callout.badge.invalid');
};

export const CalloutImageRight = ({
  title,
  description,
  tileImage,
  imageUrl,
  price,
  isExternal,
  isPreview,
  ctaLabel,
}: CalloutProps) => {
  const t = useT('translation', 'leapPages');

  return (
    <ProductCard
      isPreview={isPreview}
      data-testid="callout-right"
      imgSrc={cardImageUrl(tileImage) || imageUrl}
      className="callout group-focus:ring-0 group-focus:ring-transparent"
      footer={
        <Badge
          color="gray"
          outlineColor="gray"
          className={twMerge(
            'callout__badge',
            ctaLabel && ctaLabel.length > 25
              ? 'sm:tracking-wide'
              : 'tracking-wider'
          )}
          size="sm"
          isThemed
        >
          {ctaLabel ? ctaLabel : getBadgeLabel(t, isExternal, price)}
        </Badge>
      }
    >
      <H4 className="callout__title line-clamp-2 font-bold leading-relaxed break-words group-hover:underline group-focus:underline">
        <span>{title}</span>
      </H4>
      {description && (
        <Paragraph className="callout__description line-clamp-4 mt-1 break-words">
          {description}
        </Paragraph>
      )}
    </ProductCard>
  );
};

export const Callout = (props: CalloutProps) => {
  const { id, to, isPreview, isExternal, className } = props;
  const Anchor = isPreview ? 'button' : isExternal ? 'a' : Link;
  // enforcing the imageRight as default variant in case there is no image

  return (
    <Anchor
      to={`${to}`}
      href={`${to}`}
      id={`callout-${id}`}
      className={getCardParentClasses(twMerge('text-left', className))}
    >
      <input type="hidden" name="id" value={id} required />
      <CalloutImageRight {...props} />
    </Anchor>
  );
};
