import { useT } from '-/i18n/useI18n';
import { BlockModelsEnum } from '-/leap-models/blocks';
import { PLGFooterTypes } from '-/leap-pages/src/components/PoweredByFooter';
import { H1 } from '-/leap-pages/src/components/ui/Typography';
import { CreatorBanner } from '../components/CreatorBanner';
import Layouts from '../components/Layouts';
import StorefrontBlock from '../components/StorefrontBlock';

import type { SocialMediaLinkList } from '-/leap-pages/src/models/brand';
import type { Theme } from '-/leap-pages/src/models/themes';
import type { InternalBlockProps } from '../components/StorefrontBlock';

interface LinkInBioProps {
  name?: string;
  profileSummary?: string;
  images?: {
    profileImageUrl?: string;
    coverImageUrl?: string;
  };
  blocks: InternalBlockProps[];
  theme: Theme;
  className?: string;
  socialMedia?: SocialMediaLinkList;
  showEmptyMessage?: boolean;
  plgType?: PLGFooterTypes;
  plgReferralCode?: string;
}

const LinkInBio = ({
  name,
  profileSummary,
  images,
  blocks,
  socialMedia,
  theme,
  className,
  showEmptyMessage = false,
  plgType,
  plgReferralCode,
}: LinkInBioProps) => {
  const t = useT('translation', 'leapPages');

  return (
    <Layouts.CenteredColumn className={className} theme={theme}>
      <CreatorBanner
        name={name}
        profileSummary={profileSummary}
        images={images}
        layout={theme?.parameters?.layout}
        socialMedia={socialMedia}
      />

      <hr className="creator-banner-divider" />

      <Layouts.Main
        plg={{
          type: plgType || PLGFooterTypes.BUTTON,
          referralCode: plgReferralCode || '',
        }}
      >
        <div className="h-full">
          {blocks.length === 0 && showEmptyMessage && (
            <div className="h-full w-full border-0 text-center my-6">
              <H1 className="mb-3">{t('emptyState.title')}</H1>
              <p>{t('emptyState.description')}</p>
            </div>
          )}
          <div className="flex flex-col gap-6">
            {blocks.map((block) => (
              <StorefrontBlock
                key={block.id}
                {...block}
                isExternal={
                  [BlockModelsEnum.LINK, BlockModelsEnum.REFERRAL_LINK].includes(
                    block.blockType?.model as BlockModelsEnum,
                  )
                }
              />
            ))}
          </div>
        </div>
      </Layouts.Main>
    </Layouts.CenteredColumn>
  );
};

export { LinkInBio };
