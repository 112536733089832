import { Form, Link, useActionData } from '@remix-run/react';
import { twMerge } from 'tailwind-merge';

import { Input, Button } from '-/aslan';
import { useT } from '-/i18n';
import { BlockTypes } from '-/leap-models';
import { fieldErrorMessage, cardImageUrl } from '-/utils';
import { H4, Paragraph } from '..';
import { ProductCard } from './ui/ProductCard';

import type { PropsWithChildren } from 'react';
import type { LeapError } from '-/leap-models';
import type { Block } from '..';

interface ActionDataType {
  errors?: LeapError[];
}

const WaitlistCaptureInputs = ({ blockItemId, price, ctaLabel }: Block) => {
  const actionData = useActionData<ActionDataType>();
  const t = useT('translation', 'leapPages.callout');

  return (
    <div
      className={twMerge(
        'flex gap-2 sm:gap-3 md:gap-4',
        ctaLabel && ctaLabel.length > 18 && 'flex-col'
      )}
    >
      <label htmlFor="email" className="sr-only">
        Email
      </label>
      <Input
        className="fulfillment-input"
        inputWrapperClassName="max-w-sm rounded-md focus-within:ring focus-within:ring-green-800"
        placeholder="Email"
        type="email"
        name="email"
        inputClassName="w-full mt-0 rounded-md text-black focus:ring-0"
        error={fieldErrorMessage(actionData?.errors ?? [], 'email')?.message}
        required
      />
      <input type="hidden" name="itemId" value={blockItemId} required />
      <input
        type="hidden"
        value={BlockTypes.EMAIL_CAPTURE}
        name="productType"
      />
      <input type="hidden" value={price?.priceType} name="productPriceType" />

      <div
        className={twMerge(
          'flex gap-2 items-center',
          ctaLabel && ctaLabel.length > 18 && 'md:flex-col md:items-start'
        )}
      >
        <Button
          type="submit"
          color="yellow"
          isRounded
          isOutline
          innerButtonClassName={twMerge(
            'button-primary px-3 sm:px-6 py-1.5 focus:border-0 hover:border-0 group-hover:shadow-none hover:opacity-80 transition-none'
          )}
          value={blockItemId}
          name="itemId"
        >
          {ctaLabel ? ctaLabel : t('emailCapture.signup')}
        </Button>
      </div>
    </div>
  );
};

export const WaitlistCapture = ({
  blockItemId,
  slug,
  tileImage,
  price,
  title,
  className,
  description,
  isPreview = false,
  ctaLabel,
}: Block & PropsWithChildren & { className?: string; isPreview?: boolean }) => {
  const t = useT('translation', 'leapPages.callout');

  return (
    <div className={className}>
      <ProductCard
        isPreview={isPreview}
        imgSrc={cardImageUrl(tileImage)}
        className="callout h-full group-hover:opacity-100"
        footer={
          isPreview ? (
            <fieldset disabled>
              <WaitlistCaptureInputs
                blockItemId={blockItemId}
                slug={slug}
                price={price}
                ctaLabel={ctaLabel}
              />
            </fieldset>
          ) : (
            <Form method="post" id={`block-form-email-capture-${blockItemId}`}>
              <WaitlistCaptureInputs
                blockItemId={blockItemId}
                slug={slug}
                price={price}
                ctaLabel={ctaLabel}
              />
            </Form>
          )
        }
      >
        <H4 className="callout__title line-clamp-2 leading-relaxed font-bold">
          {title}
        </H4>
        {description && (
          <Paragraph className="callout__description leading-relaxed line-clamp-4 mt-1">
            <span className="box-decoration-clone pr-2">{description}</span>
            <Link
              to={`${slug}`}
              id={`product-block-${blockItemId}`}
              className="inline-block whitespace-nowrap underline hover:no-underline underline-offset-2 text-card-text"
            >
              {t('emailCapture.learnMore')}
            </Link>
          </Paragraph>
        )}
      </ProductCard>
    </div>
  );
};
