import { twMerge } from 'tailwind-merge';

import { Avatar } from '-/aslan/components/Avatar/Avatar';
import SocialMediaLinks from './SocialMediaLinks';
import { H1, H2, Paragraph } from './ui/Typography';

import type { z } from 'zod';
import type { LayoutEnum } from '-/leap-pages/src/models/theme-parameters';
import type { SocialMediaLinkList } from '../models/brand';

interface CreatorProfileSummary {
  name?: string;
  profileSummary?: string;
  socialMedia?: SocialMediaLinkList;
}

interface CreatorBannerProps extends CreatorProfileSummary {
  images?: {
    profileImageUrl?: string;
    coverImageUrl?: string;
  };
  layout: z.infer<typeof LayoutEnum>;
}

const CreatorProfileSummary = ({
  name,
  profileSummary,
  socialMedia,
  className,
}: CreatorProfileSummary & { className?: string }) => (
  <div className={twMerge(className, 'px-4 sm:px-6 text-center')}>
    <H1
      className={twMerge(
        'break-all',
        name && name.length > 24 && 'tracking-normal sm:tracking-wider',
        name && name.length > 16 ? 'text-base sm:text-lg' : 'text-lg sm:text-xl'
      )}
    >
      {name}
    </H1>
    <Paragraph className="max-w-md mb-3 text-sm sm:text-base leading-relaxed sm:leading-loose">
      {profileSummary}
    </Paragraph>
    <SocialMediaLinks socialMedia={socialMedia} />
  </div>
);

export const CreatorBannerStack = ({
  name,
  profileSummary,
  images,
  socialMedia,
}: CreatorBannerProps) => {
  return (
    <header
      data-testid="creator-banner-stack"
      className="creator-banner pt-12 mb-2x min-w-screen flex flex-col items-center justify-center"
    >
      <Avatar
        isRounded
        name={name}
        src={images?.profileImageUrl}
        className="creator-banner__avatar"
      />
      <CreatorProfileSummary
        name={name}
        profileSummary={profileSummary}
        socialMedia={socialMedia}
        className="creator-banner__summary flex flex-col justify-center items-center"
      />
    </header>
  );
};

export const CreatorBannerMain = ({
  name,
  profileSummary,
  images,
  socialMedia,
}: CreatorBannerProps) => {
  return (
    <header
      data-testid="creator-banner-hero-fade"
      className={twMerge(
        'w-full relative',
        'flex flex-col justify-center items-center gap-8',
        'creator-banner'
      )}
    >
      <div
        className={twMerge(
          'creator-banner__cover',
          'relative w-full',
          'h-[360px]'
        )}
      >
        <Avatar
          isRounded
          name={name}
          src={images?.profileImageUrl}
          className="creator-banner__avatar absolute left-0 right-0 mx-auto bottom-[-25px] "
        />
      </div>
      <CreatorProfileSummary
        name={name}
        profileSummary={profileSummary}
        socialMedia={socialMedia}
        className="creator-banner__summary flex flex-col justify-center items-center"
      />
    </header>
  );
};

export const CreatorBannerCompact = ({
  name,
  profileSummary,
  images,
  socialMedia,
}: CreatorBannerProps) => {
  return (
    <header
      data-testid="creator-banner-compact"
      className="w-full pt-5 px-4 sm:px-6 md:px-10"
    >
      <div className="creator-banner flex gap-2 pb-2">
        <Avatar
          className="creator-banner__avatar row-span-2 mt-2 h-16 w-16 md:h-20 md:w-20"
          isRounded
          name={name}
          src={images?.profileImageUrl}
        />
        <div className="min-w-0 px-2 sm:px-3 flex flex-col gap-2">
          <H1
            className={twMerge(
              'break-all',
              name && name.length > 24 && 'tracking-normal sm:tracking-wider',
              name && name.length > 16
                ? 'text-sm sm:text-base'
                : 'text-base sm:text-lg'
            )}
          >
            {name}
          </H1>
          <SocialMediaLinks
            socialMedia={socialMedia}
            className="justify-start px-0"
          />
        </div>
      </div>
      <H2 className="text-left py-3 text-sm sm:text-base leading-relaxed">
        {profileSummary}
      </H2>
    </header>
  );
};

export const CreatorBannerPortrait = ({
  name,
  profileSummary,
  socialMedia,
  images,
}: CreatorBannerProps) => {
  return (
    <header
      data-testid="creator-banner-portrait"
      className="creator-banner relative w-full"
    >
      {images?.profileImageUrl && (
        <div className="creator-banner__portrait relative h-[360px]" />
      )}
      <CreatorProfileSummary
        name={name}
        profileSummary={profileSummary}
        socialMedia={socialMedia}
        className="creator-banner__summary w-full flex flex-col justify-end items-center relative z-10"
      />
    </header>
  );
};

const variantMap = {
  stack: CreatorBannerStack,
  portrait: CreatorBannerPortrait,
  banner: CreatorBannerMain,
  compact: CreatorBannerCompact,
};

export const CreatorBanner = (props: CreatorBannerProps) => {
  const variant = props.layout || 'theleap';
  const Header = variantMap[variant];
  return <Header {...props} />;
};
